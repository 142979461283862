<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <el-button @click="$router.push({ name: 'news', query: { activeName: $route.query.tableActiveName } })">
          <i class="iconfont icon-fanhui" />返回
        </el-button>
      </template>
    </page-title>
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="110px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="消息内容">
          <el-col v-if="!viewEdit()" :span="12">
            <el-form-item label="重复">
              <p>{{ `${utils.isEffectiveCommon(appForm.dayStr)}&nbsp;&nbsp;&nbsp;发送时间：${utils.isEffectiveCommon(appForm.scheduledTime)}` }}</p>
            </el-form-item>
          </el-col>
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item prop="scheduledDays" label="重复">
              <ics-item-inner :prop="appForm.scheduledDays" :format="utils.isEffectiveCommon">
                <el-select v-model="appForm.scheduledDays" placeholder="" multiple @change="handleWeekPlat">
                  <el-option v-for="item in constants.week" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled" />
                </el-select>
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col v-if="isShowDateTime" :span="12">
            <el-form-item prop="scheduledTime" label="定时发送时间">
              <el-date-picker
                v-model="appForm.scheduledTime"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="isShowTime" :span="12">
            <el-form-item prop="scheduledTime" label="定时发送时间">
              <ics-item-inner :prop="appForm.scheduledTime" :format="utils.isEffectiveCommon">
                <el-time-picker
                  v-model="appForm.scheduledTime"
                  value-format="HH:mm:ss"
                  placeholder="任意时间点"
                />
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col v-if="!viewEdit()" :span="12">
            <el-form-item label="接收企业类型">
              <ics-item-inner :prop="appForm.firmTypeStr" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item prop="firmTypes" label="接收企业类型">
              <ics-item-inner :prop="appForm.firmTypes" :format="utils.isEffectiveCommon">
                <el-select v-model="appForm.firmTypes" placeholder="请选择企业类型" multiple @change="handleTypePlat">
                  <el-option label="全部" :value="0" />
                  <el-option v-for="item in constants.companyType" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled" />
                </el-select>
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col v-if="!viewEdit()" :span="12">
            <el-form-item label="接收企业">
              <ics-item-inner :prop="appForm.firmIdStr" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item prop="firmIds" label="接收企业">
              <ics-item-inner :prop="appForm.firmIds" :format="utils.isEffectiveCommon">
                <el-select v-model="appForm.firmIds" placeholder="请选择接收企业" :disabled="_.isEmpty(appForm.firmTypes)" filterable multiple @change="handleFirmPlat">
                  <el-option label="全部" :value="0" />
                  <el-option v-for="item in firmList" :key="item.itFirmCode" :label="item.companyName" :value="item.itFirmCode" :disabled="item.disabled" />
                </el-select>
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col v-if="!viewEdit()" :span="12">
            <el-form-item label="接收角色">
              <ics-item-inner :prop="appForm.roleCodeStr" :format="utils.isEffectiveCommon" />
            </el-form-item>
          </el-col>
          <el-col v-if="viewEdit()" :span="12">
            <el-form-item prop="roleCodes" label="接收角色">
              <ics-item-inner :prop="appForm.roleCodes" :format="utils.isEffectiveCommon">
                <el-select v-model="appForm.roleCodes" placeholder="请选择接收角色" :disabled="_.isEmpty(appForm.firmIds)" filterable multiple @change="handleRolePlat">
                  <el-option label="全部" :value="0" />
                  <el-option v-for="item in roleList" :key="item.roleCode" :label="item.roleName" :value="item.roleCode" :disabled="item.disabled" />
                </el-select>
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态">
              <ics-item-inner :prop="appForm.status" :format="(val)=>utils.statusFormat(Number(val), 'newsStatus')">
                <el-radio-group v-model="appForm.status">
                  <el-radio v-for="item in constants.newsStatus" :key="item.value" :label="item.value">
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="informationTitle" label="消息标题">
              <ics-item-inner :prop="appForm.informationTitle" :format="utils.isEffectiveCommon">
                <el-input v-model="appForm.informationTitle" placeholder="请输入消息标题" />
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="editor-content">
              <el-form-item prop="informationContent" label="模板内容">
                <div class="editor-container">
                  <textarea id="editor" v-model="appForm.informationContent" />
                </div>
              </el-form-item>
            </div>
          </el-col>
          <ics-button-inner v-if="viewEdit()" :loading="loading.submit" submit-title="提交" cancel-title="返回" :show-cancel="false" @submit="submitForms" />
        </ics-page-inner>
      </el-form>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  components: {},
  mixins: [routeEnterMixin],
  data () {
    return {
      id: this.$route.query.id,
      appForm: {
        scheduledTime: '',
        firmTypes: '',
        firmIds: [],
        roleCodes: [],
        informationTitle: '',
        scheduledDays: [],
        status: 0,
        informationContent: ''
      },
      isShowDateTime: false,
      isShowTime: false,
      firmList: [],
      roleList: [],
      dragVal: '',
      // 富文本编辑器默认内容
      content: '<h2>请输入内容..</h2>',
      // 富文本编辑器配置
      editorOption: {
        // Some Quill options...
      },
      rules: {
        scheduledTime: [
          { required: true, message: '请选择定时发送时间', trigger: 'change' }
        ],
        scheduledDays: [
          { required: true, message: '请选择重复', trigger: 'change' }
        ],
        firmTypes: [
          { required: true, message: '请选择企业类型', trigger: 'change' }
        ],
        firmIds: [
          { required: false, message: '请选择企业', trigger: 'change' }
        ],
        roleCodes: [
          { required: false, message: '请选择角色', trigger: 'change' }
        ],
        informationTitle: [
          { required: true, message: '请输入消息标题', trigger: 'blur' }
        ],
        informationContent: [
          { required: true, message: '请输入消息内容', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    if (this.id) {
      this.getDetail()
    }
    this.$nextTick(() => {
      this.editorCkeditor()
    })
  },
  methods: {
    editorCkeditor () {
      this.editor = CKEDITOR.replace('editor', {
        removeButtons: 'Maximize,Source,Templates,About,CreateDiv,Language,BidiRtl,BidiLtr,Flash,Smiley,SpecialChar,Iframe,PageBreak,HiddenField,ImageButton,Button,Textarea,TextField,Select,Radio,Checkbox,SelectAll,Save,NewPage,ExportPdf,Preview,Print,Blockquote,ShowBlocks',
        image_previewText: ' ',
        isReadOnly: true,
        filebrowserImageUploadUrl: this.filebrowserImageUploadUrl
      })
    },
    getDetail () {
      this.loading.detail = true
      this.api.system.information.getDetail(this.id).then(result => {
        const data = result.data.data
        this.appForm = data
        this.editor.setData(this.appForm.informationContent)
        if (!this.viewEdit()) {
          this.editor.setReadOnly(true)
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    handleWeekPlat (val) {
      const data = val || []
      if (data.length > 0) {
        data.forEach(item => {
          let v = []
          if (item  === 0) {
            this.constants.week.forEach(weekItem => {
              if (weekItem.value !== 0) {
                this.isShowTime = false
                this.isShowDateTime = true
                this.appForm.scheduledTime = ''
                weekItem.disabled = true
              }
            })
            v.push(item)
            this.appForm.scheduledDays = v
          } else {
            this.constants.week.forEach(item => {
              this.isShowTime = true
              this.isShowDateTime = false
              this.appForm.scheduledTime = ''
              item.disabled = false
            })
          }
        })
      } else {
        this.constants.week.forEach(item => {
          item.disabled = false
        })
      }
    },
    handleTypePlat (val) {
      this.rules.firmIds[0].required = true
      const data = val || []
      let appKey = []
      if (data.length > 0) {
        data.forEach(item => {
          let v = []
          if (item === 0) {
            appKey = ['finance-frame-core', 'finance-frame-customer', 'finance-frame-capital', 'finance-frame-channel']
            this.constants.companyType.forEach(companyItem => {
              if (companyItem.value !== 0) {
                companyItem.disabled = true
              }
            })
            v.push(item)
            this.appForm.firmTypes = v
          } else {
            appKey.push(item)
            this.constants.companyType.forEach(companyItem => {
              companyItem.disabled = false
            })
          }
        })
        this.getFirmByAppKey(appKey.join(','))
      } else {
        this.constants.companyType.forEach(item => {
          item.disabled = false
        })
      }
    },
    handleFirmPlat (val) {
      this.rules.roleCodes[0].required = true
      const data = val || []
      let itFirmCode = []
      if (data.length > 0) {
        data.forEach(item => {
          let v = []
          if (item === 0) {
            this.firmList.forEach(firmItem => {
              itFirmCode.push(firmItem.itFirmCode)
              if (firmItem.value !== 0) {
                firmItem.disabled = true
              }
            })
            v.push(item)
            this.appForm.firmIds = v
          } else {
            itFirmCode.push(item)
            this.firmList.forEach(firmItem => {
              firmItem.disabled = false
            })
          }
        })
        this.getRole(itFirmCode)
      } else {
        this.firmList.forEach(item => {
          item.disabled = false
        })
      }
    },
    handleRolePlat (val) {
      const data = val || []
      if (data.length > 0) {
        data.forEach(item => {
          let v = []
          if (item === 0) {
            this.roleList.forEach(roleItem => {
              if (roleItem.value !== 0) {
                roleItem.disabled = true
              }
            })
            v.push(item)
            this.appForm.roleCodes = v
          } else {
            this.roleList.forEach(roleItem => {
              roleItem.disabled = false
            })
          }
        })
      } else {
        this.roleList.forEach(item => {
          item.disabled = false
        })
      }
    },
    getFirmByAppKey (val) {
      this.api.admittance.getFirmByAppKey(val).then(result => {
        const data = []
        result.data.data.forEach(item => {
          data.push({itFirmCode: item.itFirmCode, companyName: item.companyName, disabled: false})
        })
        this.firmList = data
      })
    },
    getRole (row) {
      this.api.system.role.findRoles(row).then(result => {
        const data = []
        result.data.data.forEach(item => {
          data.push({roleCode: item.roleCode, roleName: item.roleName, disabled: false})
        })
        this.roleList = data
      })
    },
    submitForms () {
      const contractContent = this.editor.getData()
      this.appForm.informationContent = contractContent
      this.$refs.appForm.validate().then(() => {
        const data = this._.cloneDeep(this.appForm)
        this.loading.submit = true
        this.api.system.information.add(data).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('操作成功')
            this.$router.back()
          } else {
            this.$message.error(result.data.message)
          }
        }).catch(e => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style scoped>
</style>
